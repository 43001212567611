var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "student-documents" } },
    [
      _c(
        "vs-popup",
        {
          staticClass: "popup80 noOverflow",
          attrs: {
            fullscreen: false,
            title: _vm.showPdfTitle,
            active: _vm.popupShowPdf,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupShowPdf = $event
            },
          },
        },
        [
          _c("object", {
            staticStyle: { height: "600px" },
            attrs: { data: _vm.srcPdf, width: "100%" },
          }),
        ]
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: "Declaração de Comparecimento",
            active: _vm.popupComparecimento,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupComparecimento = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "date",
                    label: "Data Inicial",
                    "data-vv-scope": "comparecimento",
                    "data-vv-as": "Data inicial",
                    name: "startDate",
                  },
                  model: {
                    value: _vm.comparecimento.startDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.comparecimento, "startDate", $$v)
                    },
                    expression: "comparecimento.startDate",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("comparecimento.startDate"),
                        expression: "errors.has('comparecimento.startDate')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("comparecimento.startDate")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "date",
                    label: "Data Final",
                    "data-vv-scope": "comparecimento",
                    "data-vv-as": "Data final",
                    name: "finalDate",
                  },
                  model: {
                    value: _vm.comparecimento.endDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.comparecimento, "endDate", $$v)
                    },
                    expression: "comparecimento.endDate",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("comparecimento.finalDate"),
                        expression: "errors.has('comparecimento.finalDate')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("comparecimento.finalDate")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "time",
                    label: "Hora Inicial",
                    "data-vv-scope": "comparecimento",
                    "data-vv-as": "Hora Inicial",
                    name: "inicial_hour",
                  },
                  model: {
                    value: _vm.comparecimento.inicial_hour,
                    callback: function ($$v) {
                      _vm.$set(_vm.comparecimento, "inicial_hour", $$v)
                    },
                    expression: "comparecimento.inicial_hour",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("comparecimento.inicial_hour"),
                        expression: "errors.has('comparecimento.inicial_hour')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.errors.first("comparecimento.inicial_hour"))
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "time",
                    label: "Hora Final",
                    "data-vv-scope": "comparecimento",
                    "data-vv-as": "Hora Final",
                    name: "final_hour",
                  },
                  model: {
                    value: _vm.comparecimento.final_hour,
                    callback: function ($$v) {
                      _vm.$set(_vm.comparecimento, "final_hour", $$v)
                    },
                    expression: "comparecimento.final_hour",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("comparecimento.final_hour"),
                        expression: "errors.has('comparecimento.final_hour')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.errors.first("comparecimento.final_hour"))
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "Motivo" },
                  model: {
                    value: _vm.comparecimento.motivo,
                    callback: function ($$v) {
                      _vm.$set(_vm.comparecimento, "motivo", $$v)
                    },
                    expression: "comparecimento.motivo",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupComparecimento = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: !_vm.validateComparecimento },
                      on: {
                        click: function ($event) {
                          return _vm.showPdf(2)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: "Declaração de Agendamento",
            active: _vm.popupAgendamento,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupAgendamento = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "date",
                    label: "Data inicial",
                    "data-vv-scope": "agendamento",
                    "data-vv-as": "Data final",
                    name: "startDate",
                  },
                  model: {
                    value: _vm.agendamento.startDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.agendamento, "startDate", $$v)
                    },
                    expression: "agendamento.startDate",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("agendamento.startDate"),
                        expression: "errors.has('agendamento.startDate')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("agendamento.startDate")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "date",
                    label: "Data final",
                    "data-vv-scope": "agendamento",
                    "data-vv-as": "Data inicial",
                    name: "finalDate",
                  },
                  model: {
                    value: _vm.agendamento.endDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.agendamento, "endDate", $$v)
                    },
                    expression: "agendamento.endDate",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("agendamento.finalDate"),
                        expression: "errors.has('agendamento.finalDate')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("agendamento.finalDate")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "time",
                    label: "Hora Inicial",
                    "data-vv-scope": "agendamento",
                    "data-vv-as": "Hora Inicial",
                    name: "inicial_hour",
                  },
                  model: {
                    value: _vm.agendamento.inicial_hour,
                    callback: function ($$v) {
                      _vm.$set(_vm.agendamento, "inicial_hour", $$v)
                    },
                    expression: "agendamento.inicial_hour",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("agendamento.inicial_hour"),
                        expression: "errors.has('agendamento.inicial_hour')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("agendamento.inicial_hour")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate.initial",
                      value: "required",
                      expression: "'required'",
                      modifiers: { initial: true },
                    },
                  ],
                  staticClass: "w-full mt-0",
                  attrs: {
                    type: "time",
                    label: "Hora Final",
                    "data-vv-scope": "agendamento",
                    "data-vv-as": "Hora Final",
                    name: "final_hour",
                  },
                  model: {
                    value: _vm.agendamento.final_hour,
                    callback: function ($$v) {
                      _vm.$set(_vm.agendamento, "final_hour", $$v)
                    },
                    expression: "agendamento.final_hour",
                  },
                }),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("agendamento.final_hour"),
                        expression: "errors.has('agendamento.final_hour')",
                      },
                    ],
                    staticClass: "text-danger text-sm",
                  },
                  [_vm._v(_vm._s(_vm.errors.first("agendamento.final_hour")))]
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-full w-full mt-0" },
              [
                _c("vs-input", {
                  staticClass: "w-full mt-0",
                  attrs: { label: "Motivo" },
                  model: {
                    value: _vm.agendamento.motivo,
                    callback: function ($$v) {
                      _vm.$set(_vm.agendamento, "motivo", $$v)
                    },
                    expression: "agendamento.motivo",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupAgendamento = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: !_vm.validateAgendamento },
                      on: {
                        click: function ($event) {
                          return _vm.showPdf(3)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup50",
          attrs: {
            fullscreen: false,
            title: "Declaração Rebaixamento de Categ.",
            active: _vm.popupRebaixamento,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupRebaixamento = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Exerce AR"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    clearable: false,
                    reduce: (option) => option.value,
                    options: [
                      { value: 1, label: "SIM" },
                      { value: 0, label: "NÃO" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.rebaixamento.ar,
                    callback: function ($$v) {
                      _vm.$set(_vm.rebaixamento, "ar", $$v)
                    },
                    expression: "rebaixamento.ar",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-3/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria Desejada"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    clearable: false,
                    reduce: (option) => option.value,
                    options: _vm.categoryCNHOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.rebaixamento.catDesejada,
                    callback: function ($$v) {
                      _vm.$set(_vm.rebaixamento, "catDesejada", $$v)
                    },
                    expression: "rebaixamento.catDesejada",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupRebaixamento = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.showPdf(8)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "popup80",
          attrs: {
            fullscreen: false,
            title: "Controle de Frequência",
            active: _vm.popupFrequencyControl,
          },
          on: {
            "update:active": function ($event) {
              _vm.popupFrequencyControl = $event
            },
          },
        },
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Diretor de Ensino"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    clearable: false,
                    reduce: (option) => option.value,
                    options: _vm.employees,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.frequencyControl.teaching_director_id,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.frequencyControl,
                        "teaching_director_id",
                        $$v
                      )
                    },
                    expression: "frequencyControl.teaching_director_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Instrutor"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    clearable: false,
                    reduce: (option) => option.value,
                    options: _vm.employees,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.frequencyControl.instructor_id,
                    callback: function ($$v) {
                      _vm.$set(_vm.frequencyControl, "instructor_id", $$v)
                    },
                    expression: "frequencyControl.instructor_id",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Serviço"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    clearable: false,
                    reduce: (option) => option.value,
                    options: [
                      { value: 1, label: "Primeira Habilitação" },
                      { value: 2, label: "Adição" },
                      { value: 3, label: "Mudança de categoria" },
                    ],
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.frequencyControl.service,
                    callback: function ($$v) {
                      _vm.$set(_vm.frequencyControl, "service", $$v)
                    },
                    expression: "frequencyControl.service",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/4 w-full mt-0" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v("Categoria"),
                ]),
                _c("v-select", {
                  staticClass: "vue_select_drop_size_90",
                  attrs: {
                    clearable: false,
                    reduce: (option) => option.value,
                    options: _vm.category2CNHOptions,
                    placeholder: "Selecione",
                    dir: _vm.$vs.rtl ? "rtl" : "ltr",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "no-options",
                      fn: function ({}) {
                        return [
                          _vm._v(
                            "\n              Nenhum resultado encontrado.\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.frequencyControl.category,
                    callback: function ($$v) {
                      _vm.$set(_vm.frequencyControl, "category", $$v)
                    },
                    expression: "frequencyControl.category",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-divider"),
          _c("div", { staticClass: "vx-row" }, [
            _c("div", { staticClass: "vx-col w-full" }, [
              _c(
                "div",
                { staticClass: "mt-4 flex flex-wrap items-center justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-auto",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.popupFrequencyControl = false
                        },
                      },
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      on: {
                        click: function ($event) {
                          return _vm.showPdf(13)
                        },
                      },
                    },
                    [_vm._v("Gerar Documento")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "vx-card p-2" }, [
        _c(
          "div",
          { staticClass: "flex flex-wrap items-center" },
          [
            _c("div", { staticClass: "flex-grow" }, [
              !_vm.accordionDocumentTemplate
                ? _c("div", [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v("Aluno(s)"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "mt-0 flex flex-wrap items-center justify",
                      },
                      [
                        _c("v-select", {
                          staticClass: "w-90__",
                          attrs: {
                            multiple: "",
                            clearable: false,
                            filterable: true,
                            reduce: (option) => option.value,
                            options: _vm.studentOptions,
                            placeholder: "Digite CPF ou nome do aluno...",
                            dir: _vm.$vs.rtl ? "rtl" : "ltr",
                          },
                          on: { search: _vm.debouncedGetSearchStudent },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "no-options",
                                fn: function ({}) {
                                  return [
                                    _vm._v(
                                      "\n                Nenhum resultado encontrado.\n              "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            133835702
                          ),
                          model: {
                            value: _vm.students,
                            callback: function ($$v) {
                              _vm.students = $$v
                            },
                            expression: "students",
                          },
                        }),
                        _c("feather-icon", {
                          staticClass: "ml-auto inline-flex rounded-full",
                          attrs: {
                            title: "Limpar seleção",
                            icon: "MinusCircleIcon",
                            svgClasses:
                              "w-8 h-8 cursor-pointer hover:text-primary",
                          },
                          on: { click: _vm.clearSelectedStudents },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("vs-input", {
              staticClass:
                "sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-3 mt-4 ml-6",
              staticStyle: { "margin-top": "19px !important" },
              attrs: { placeholder: "Busca rápida por documento..." },
              on: { input: _vm.updateSearchQuery },
              model: {
                value: _vm.searchQuery,
                callback: function ($$v) {
                  _vm.searchQuery = $$v
                },
                expression: "searchQuery",
              },
            }),
            _c(
              "vx-tooltip",
              {
                staticClass: "ml-1 mb-4 sm:mt-8 md:mb-0",
                staticStyle: { "margin-top": "19px !important" },
                attrs: {
                  text: _vm.accordionDocumentTemplate
                    ? "Fechar Cadastro de Documento"
                    : "Cadastrar Documento",
                  position: "top",
                },
              },
              [
                !_vm.accordionDocumentTemplate
                  ? _c("feather-icon", {
                      attrs: {
                        icon: "FilePlusIcon",
                        svgClasses: "h-8 w-8 cursor-pointer hover:text-primary",
                      },
                      on: { click: _vm.addDocumentTemplate },
                    })
                  : _c("feather-icon", {
                      attrs: {
                        icon: "FileMinusIcon",
                        svgClasses: "h-8 w-8 cursor-pointer hover:text-primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.accordionDocumentTemplate = false
                        },
                      },
                    }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.accordionDocumentTemplate
          ? _c("div", { staticClass: "vx-row mt-0" }, [
              _c("div", { staticClass: "vx-col md:w-full w-full mt-0" }, [
                _c("div", { staticClass: "vx-row" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-3/4 w-full mt-0" },
                    [
                      _c("vs-input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate.initial",
                            value: "required",
                            expression: "'required'",
                            modifiers: { initial: true },
                          },
                        ],
                        staticClass: "w-full mt-0",
                        attrs: {
                          label: "Título",
                          "data-vv-as": "Título",
                          "data-vv-scope": "documentTemplate",
                          name: "title",
                        },
                        model: {
                          value: _vm.documentTemplate.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.documentTemplate, "title", $$v)
                          },
                          expression: "documentTemplate.title",
                        },
                      }),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("documentTemplate.title"),
                              expression:
                                "errors.has('documentTemplate.title')",
                            },
                          ],
                          staticClass: "text-danger text-sm",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.errors.first("documentTemplate.title"))
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                    [
                      _c("label", { staticClass: "vs-input--label" }, [
                        _vm._v("Cabeçalho"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          reduce: (option) => option.value,
                          clearable: false,
                          options: [
                            { label: "SIM", value: true },
                            { label: "NÃO", value: false },
                          ],
                          placeholder: "Selecione",
                          dir: _vm.$vs.rtl ? "rtl" : "ltr",
                        },
                        model: {
                          value: _vm.documentTemplate.print_header,
                          callback: function ($$v) {
                            _vm.$set(_vm.documentTemplate, "print_header", $$v)
                          },
                          expression: "documentTemplate.print_header",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "vx-row mt-4" }, [
                  _c("div", { staticClass: "vx-col md:w-3/4 w-full mt-0" }, [
                    _c(
                      "div",
                      [
                        _c("ckeditor", {
                          attrs: {
                            editor: _vm.editor,
                            config: _vm.editorConfig,
                          },
                          on: { ready: _vm.onReady },
                          model: {
                            value: _vm.documentTemplate.body,
                            callback: function ($$v) {
                              _vm.$set(_vm.documentTemplate, "body", $$v)
                            },
                            expression: "documentTemplate.body",
                          },
                        }),
                        _c("div", { staticClass: "vx-row" }, [
                          _c("div", { staticClass: "vx-col w-full" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mt-0 flex flex-wrap items-center justify-end",
                              },
                              [
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "ml-auto mt-4",
                                    attrs: { color: "secondary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.accordionDocumentTemplate = false
                                      },
                                    },
                                  },
                                  [_vm._v("Cancelar")]
                                ),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "ml-4 mt-4",
                                    attrs: {
                                      disabled: !_vm.validateDocumentFormModel,
                                    },
                                    on: { click: _vm.saveDocumentTemplate },
                                  },
                                  [_vm._v("Salvar Modelo De Documento")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "vx-col md:w-1/4 w-full mt-0" },
                    [
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Variáveis do Aluno"),
                        ]),
                      ]),
                      _vm._l(_vm.studentVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_studentVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Endereço"),
                        ]),
                      ]),
                      _vm._l(_vm.addressVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_addressVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Avalista"),
                        ]),
                      ]),
                      _vm._l(_vm.guarantorVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_guarantorVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _c(
                              "vx-tooltip",
                              {
                                attrs: {
                                  text: variable.variable,
                                  position: "left",
                                },
                              },
                              [
                                _c("span", { staticClass: "under mb-1" }, [
                                  _vm._v(_vm._s(variable.label)),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("vs-divider", { attrs: { position: "left" } }, [
                        _c("strong", { staticClass: "text-sm" }, [
                          _vm._v("Contrato"),
                        ]),
                      ]),
                      _vm._l(_vm.contractVariables, function (variable, i) {
                        return _c(
                          "p",
                          {
                            key: `${i}_contractVariables`,
                            staticClass: "text-sm cursor-pointer",
                            on: {
                              click: function ($event) {
                                return _vm.copyVariable(variable.variable)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(variable.label) +
                                "\n            "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "vx-row mt-4" }, [
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-0" },
            [
              _c("h3", [_vm._v("Modelos")]),
              _c(
                "vs-table",
                {
                  staticClass: "bordered",
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: { data: _vm.preDocumentsFiltered, hoverFlat: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (doc, i) {
                          return _c(
                            "vs-tr",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "12px",
                                opacity: "1 !important",
                              },
                            },
                            [
                              _c(
                                "vs-td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-default font-semibold",
                                    },
                                    [_vm._v(_vm._s(doc.title))]
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { staticClass: "flex flex-wrap items-center" },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      title: "Imprimir",
                                      icon: "PrinterIcon",
                                      svgClasses:
                                        "h-5 w-5 mr-2 hover:text-primary cursor-pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.print(doc, "PDF")
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("TÍTULO")]),
                      ]),
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("AÇÃO")]),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col md:w-1/2 w-full mt-0" },
            [
              _c("h2", [_vm._v("Documentos Criados")]),
              _c(
                "vs-table",
                {
                  staticClass: "bordered",
                  staticStyle: { overflow: "-webkit-paged-y" },
                  attrs: {
                    noDataText: "Nenhum dado a ser mostrado.",
                    data: _vm.documentsFiltered,
                    hoverFlat: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ data }) {
                        return _vm._l(data, function (doc, i) {
                          return _c(
                            "vs-tr",
                            {
                              key: i,
                              staticStyle: {
                                "font-size": "12px",
                                opacity: "1 !important",
                              },
                            },
                            [
                              _c(
                                "vs-td",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-default font-semibold",
                                    },
                                    [_vm._v(_vm._s(doc.title))]
                                  ),
                                ]
                              ),
                              _c(
                                "vs-td",
                                { staticClass: "flex flex-wrap items-center" },
                                [
                                  _c("feather-icon", {
                                    attrs: {
                                      title: "Imprimir",
                                      icon: "PrinterIcon",
                                      svgClasses:
                                        "h-5 w-5 mr-2 hover:text-primary cursor-pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.printTemplate(doc, "PDF")
                                      },
                                    },
                                  }),
                                  _c("feather-icon", {
                                    attrs: {
                                      title: "Alterar",
                                      icon: "EditIcon",
                                      svgClasses:
                                        "h-5 w-5 mr-2 hover:text-danger cursor-pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.update(doc)
                                      },
                                    },
                                  }),
                                  _c("feather-icon", {
                                    attrs: {
                                      title: "Excluir",
                                      icon: "Trash2Icon",
                                      svgClasses:
                                        "h-5 w-5 mr-2 hover:text-danger cursor-pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmDelete(doc)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        })
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "thead" },
                    [
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("TÍTULO")]),
                      ]),
                      _c("vs-th", { staticStyle: { "font-size": "11px" } }, [
                        _c("span", [_vm._v("AÇÃO")]),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }